<ng-container *transloco="let t">
<ngx-form-factory
class="flex items-center mt-30"
[formGroup]="filterForm"
[submitButtonOptions]="{
  show: false
}"
>

  <div form-body class="flex mb-4 justify-between">
    <mat-form-field appearance="fill" class="w-60 rounded-3xl ml-8 mb-2">
      <mat-label class="font-normal text-sm">{{t ('FINANCE.DATE_RANGE')}}</mat-label>
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="{{t ('FINANCE.START_DATE')}}" />
        <input matEndDate formControlName="end" placeholder="{{t ('FINANCE.END_DATE')}}" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
     <widget-button label="{{t ('FINANCE.APPLY')}}" [disabled]="isFilterEmpty" (handleSubmit)="applyFilter()"
                 buttonClass="mx-2 w-26 mt-6 ml-6 h-12 max-h-11 text-white rounded-3xl" color="primary"></widget-button>
    <button
      mat-button
      class="w-26 mt-7"
      [disabled]="isFilterEmpty"
      (click)="reset()"
    >
      <mat-icon class="icon-size-5 mr-2">filter_alt</mat-icon>
      <span>{{t ('FINANCE.RESET')}}</span>
    </button>
  </div>
</ngx-form-factory>
</ng-container>