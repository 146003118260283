<ng-container *ngIf="visible$ | async">
  <mat-progress-bar
    aria-label="Network progress bar"
    mode="indeterminate"
    class="fixed top-0 left-0 right-0 w-full z-[9999]"
  ></mat-progress-bar>
</ng-container>
<partial-layout
  [navigation]="navigation"
  [user]="user$ | async"
  applicationName="{{'INSTRUCTOR' | transloco}}"
  app="INSTRUCTOR"
>
  <router-outlet *ngIf="true"></router-outlet>
</partial-layout>
