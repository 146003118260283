import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SelectOption} from '@ezzabuzaid/ngx-form-factory';
import {Observable, of} from 'rxjs';
import {
  AbstractUserService,
  ApiConstants, BaseResponse, CategoryModel,
  PaginationQuery,
  PaginationResult,
  WorkshopModel
} from "@tante-tobi-web/infrastructure";
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class WorkshopsService {
  constructor(
    private _httpClient: HttpClient,
    private _userService: AbstractUserService,
    private translocoService: TranslocoService
  ) {
  }

  getWorkshops(query: PaginationQuery) {
    return this._httpClient
      .configure({
        fullResponse: true,
      })
      .get<PaginationResult<WorkshopModel.Workshop[]>>(`${ApiConstants.WORKSHOPS.filterWorkshops}?${query.asString()}`);
  }

  getWorkshopById(id: number) {
    return this._httpClient.get<WorkshopModel.WorkshopEngagement>(
      `${ApiConstants.WORKSHOPS.filterWorkshops}${id}`
    );
  }

  getEngagedWorkshops(query: PaginationQuery): Observable<PaginationResult<any>> {
    return this._httpClient
      .configure({fullResponse: true})
      .get<PaginationResult<WorkshopModel.Workshop[]>>(`${ApiConstants.WORKSHOPS.engaged}?${query.asString()}`);
  }

  getDurations() {
    return of([
      new SelectOption(1, this.translocoService.translate('WORKSHOP.ONE_DAY')),
      new SelectOption(2, this.translocoService.translate('WORKSHOP.TWO_DAYS')),
      new SelectOption(3, this.translocoService.translate('WORKSHOP.THREE_DAYS')),
      new SelectOption(4, this.translocoService.translate('WORKSHOP.FOUR_DAYS')),
    ]);
  }

  getLevels() {
    return of([
      new SelectOption('BEGINNER', this.translocoService.translate('BEGINNER')),
      new SelectOption('INTERMEDIATE', this.translocoService.translate('INTERMEDIATE')),
      new SelectOption('PROFESSIONAL', this.translocoService.translate('PROFESSIONAL')),
    ]);
  }

  getTeachingMethods() {
    return of([
      new SelectOption('ONLINE', this.translocoService.translate('ONLINE')),
      new SelectOption('ON_SITE', this.translocoService.translate('ONSITE')),
    ]);
  }

  getCategories(isEnabled = true) {
    return this._httpClient.get<CategoryModel.Category[]>(ApiConstants.CATEGORIES.getCategories, {params:{isEnabled}});
  }

  getSubCategories(categoryId: string) {
    return this._httpClient.get<CategoryModel.SubCategory[]>(`${ApiConstants.CATEGORIES.getCategorySubCategories}/${categoryId}`);
  }

  getTopics(subCategoryId: string) {
    return this._httpClient.get<CategoryModel.Topic[]>(`${ApiConstants.CATEGORIES.getSubcategoryTopics}/${subCategoryId}`);
  }

  suggestWorkshop(suggestWorkshop: WorkshopModel.SuggestWorkshop) {
    this._prepareSuggestWorkshopRequest(suggestWorkshop);
    return this._httpClient
      .configure({
        snackbar: {
          successMessage: this.translocoService.translate('SUCCESS'),
          errorMessage: this.translocoService.translate('ERRORS.SOMETHING_WENT_WRONG'),
        },
      })
      .post(ApiConstants.SUGGESTIONS.suggestWorkshop, suggestWorkshop);
  }

  engageWorkshops(data: WorkshopModel.EngageWorkshopRequest) {
    return this._httpClient
      .configure({
        fullResponse: true,
        snackbar: {successMessage: this.translocoService.translate('SUCCESS')}
      })
      .post<BaseResponse<any>>(ApiConstants.WORKSHOPS.engage, data);
  }

  engageWithUserData(engageWorkshopForm: WorkshopModel.EngageWorkshopRequest) {
    return this.engageWorkshops(engageWorkshopForm);
  }

  withdrawWorkshops(withdrawWorkshopRequest: WorkshopModel.WithdrawWorkshopRequest) {
    return this._httpClient
      .configure({
        snackbar: {successMessage: this.translocoService.translate('SUCCESS')}
      })
      .post<any>(ApiConstants.WORKSHOPS.withdraw, withdrawWorkshopRequest);
  }

  private _prepareSuggestWorkshopRequest(suggestWorkshop: WorkshopModel.SuggestWorkshop) {
    if(typeof (suggestWorkshop.categoryName) !== 'string') {
      suggestWorkshop.categoryName = suggestWorkshop.categoryName?.name
    }
    if(typeof (suggestWorkshop.topicName) !== 'string') {
      suggestWorkshop.topicName = suggestWorkshop.topicName?.name
    }
    if(typeof (suggestWorkshop.subCategoryName) !== 'string') {
      suggestWorkshop.subCategoryName = suggestWorkshop.subCategoryName?.name
    }
  }
}
