<div class="container px-8">
  <instructor-statements-filter
    (filter)="table.refresh()"
  ></instructor-statements-filter>
  <partial-table
    #table
    class="mb-4"
    [displayColumns]="displayColumns"
    [fetchProvider]="fetchProvider"
  >
  </partial-table>
</div>
