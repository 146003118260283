import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {Observable} from "rxjs";
import {BookingModel, PaginationQuery, PaginationResult} from "@tante-tobi-web/infrastructure";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  _selectedMembersForCertificate: Record<number, boolean> = {}
  set selectedMembersForCertificate(value: Record<number, boolean>) {
    this._selectedMembersForCertificate = {...this._selectedMembersForCertificate, ...value}
  }
  get selectedMembersForCertificate(): Record<number, boolean> {
    return this._selectedMembersForCertificate
  }
  resetSelectedMembersForCertificate() {
    this._selectedMembersForCertificate = {}
  }
  constructor(
    private _httpClient: HttpClient,
  ) {
  }

  getBookingUrl(bookingId: number): Observable<string> {
    return this._httpClient.configure({snackbar: {errorMessage: 'Something went wrong'}}).post<BookingModel.JoinMeetingResponse>(`instructor/bookings/join-meeting/${bookingId}`, {})
      .pipe(map(data => data.meetingUrl))
  }

  getBookingsList(query: PaginationQuery): Observable<PaginationResult<BookingModel.Booking[]>> {
    return this._httpClient.configure({fullResponse: true}).get<PaginationResult<BookingModel.Booking[]>>(`instructor/bookings?${query.asString()}`)
  }

  getBookingById(id: number): Observable<BookingModel.Booking> {
    return this._httpClient.get<BookingModel.Booking>(`instructor/bookings/${id}`)
  }

  completeBooking(id: number, clientId: number[] = []): Observable<any> {
    return this._httpClient.post(`instructor/bookings/complete/${id}`, {clientId})
  }

  getInstructorInvoices(bookingId: number) {
    const params = new HttpParams().append('bookingId', bookingId);
    return this._httpClient
      .configure({snackbar: {errorMessage: 'BOOKING.NO_INVOICE'}})
      .get<any>('instructor/invoices', {
        params,
      });
  }

  issueCertificate(issueCertificate: BookingModel.IIssueCertificate): Observable<any> {
    return this._httpClient
      .configure({snackbar:{successMessage:'SUCCESS', errorMessage:'ERRORS.SOMETHING_WENT_WRONG'}})
      .post<any>(`instructor/bookings/issue-certificates`, issueCertificate);
  }

  downloadStatement(statementId: number): Observable<any> {
    return this._httpClient.configure({snackbar: {successMessage:'SUCCESS'}})
      .get(`instructor/invoices/statement/${statementId}`, {})
  }
}

