<ng-container>
  <a [stopPropagation]="'click'" (click)="openInvoice()" target="_blank"
  >
    <mat-icon
      matTooltip="preview Invoice"
      matSuffix
      class="cursor-pointer ml-10"
    >
      visibility
    </mat-icon>
  </a>
</ng-container>
