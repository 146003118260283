import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AbstractUserService, ApiConstants, ApplicationUser, InstructorUser } from '@tante-tobi-web/infrastructure';

@Injectable({
  providedIn: 'root',
})
export class UserService extends AbstractUserService {
  constructor(protected _httpClient: HttpClient) {
    super();
  }

  override getUser() {
    return this._httpClient.get<ApplicationUser>(ApiConstants.PROFILE.viewProfile).pipe(tap((response) => (this.user = response)));
  }

  updateUser(user: InstructorUser) {
    return this._httpClient.configure({ snackbar: { successMessage: 'SUCCESS' } }).put(ApiConstants.PROFILE.profile, user);
  }
}
