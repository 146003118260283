import {RoutesConstants} from "@tante-tobi-web/infrastructure";
import {FuseNavigationItem} from "@tante-tobi-web/partials";

export default [
  {
    id: 'application',
    title: 'NAVIGATION.APPLICATION',
    type: 'group',
    children: [
      {
        id: 'application.dashboard',
        title: 'NAVIGATION.DASHBOARD',
        type: 'basic',
        icon: 'dashboard',
        link: '/applications',
      },
    ],
  },
  {
    type: 'divider',
  },
  {
    id: 'calendar',
    title: 'NAVIGATION.CALENDER',
    type: 'basic',
    icon: 'date_range',
    link: RoutesConstants.CALENDER.withSlash,
  },

  {
    id: 'booking',
    title: 'NAVIGATION.BOOKINGS',
    type: 'basic',
    icon: 'calendar_today',
    link: 'bookings',
  },
  {
    type: 'divider',
  },
  {
    id: 'workshop',
    title: 'NAVIGATION.WORKSHOP',
    type: 'group',
    children: [
      {
        id: 'workshop.discover',
        title: 'NAVIGATION.DISCOVER_WORKSHOP',
        type: 'basic',
        icon: 'image_search',
        link: RoutesConstants.DISCOVER.withSlash,
      },
      {
        id: 'workshop.engaged',
        title: 'NAVIGATION.ENGAGED_WORKSHOPS',
        type: 'basic',
        icon: 'library_add_check',
        link: RoutesConstants.ENGAGED.withSlash,
      },
    ],
  },

  {
    id: 'finance',
    title: 'NAVIGATION.FINANCE',
    type: 'group',
    children: [
      {
        id: 'finance.statements',
        title: 'NAVIGATION.STATEMENTS',
        type: 'basic',
        icon: 'equalizer',
        link: RoutesConstants.STATEMENTS_LIST.withSlash,
      },
    ],
  },

  // {
  //   id: 'ratings',
  //   title: 'NAVIGATION.RATINGS',
  //   type: 'basic',
  //   icon: 'trending_up',
  //   link: 'ratings',
  // },
] as FuseNavigationItem[];
