import {Component, Inject} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {NAVIGATOR} from '@ng-web-apis/common';
import {TranslocoService, TRANSLOCO_LOADER} from '@ngneat/transloco';
import {TranslocoPersistTranslations} from '@ngneat/transloco-persist-translations';
import {ThemeState} from '@tante-tobi-web/infrastructure';
import {filter, mergeMapTo, switchMap} from 'rxjs';
import {environment} from '../environments/environment';
import {AuthService, UserService} from './services';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'instructor-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    class: 'flex flex-auto w-full h-full',
  },
  styles: [],
})
export class AppComponent {
  constructor(
    private _themeState: ThemeState,
    private _authService: AuthService,
    private _userService: UserService,
    private _translocoService: TranslocoService,
    private _afMessaging: AngularFireMessaging,
    private _snackbar: MatSnackBar,
    @Inject(NAVIGATOR) private _navigator: Navigator,
    @Inject(TRANSLOCO_LOADER) private _loader: TranslocoPersistTranslations
  ) {
    this.registerServiceWorker()
    this.hideAllConsoleLogs();
    this._themeState.activateTheme('light');
    this._authService.authenticated$
      .pipe(
        filter((auth) => auth),
        switchMap(() => this._userService.load())
      )
      .subscribe();
  }

  // FIXME: maintain angular version and remove the translation on deploy (version change).
  // FIXME: https://ngneat.github.io/transloco/docs/blog-posts
  clearTranslationsCache() {
    this._loader.clearCache();
  }

  requestNotificationPermission() {
    this._afMessaging.requestPermission // request permission
      .pipe(mergeMapTo(this._afMessaging.tokenChanges)) // get a token
      .subscribe({
          next: (token) => {
            // eslint-disable-next-line no-restricted-globals
            localStorage.setItem('deviceToken', JSON.stringify(token))
          }, error: (error) => {
            console.error(error);
          }
        }
      );
  }

  registerServiceWorker(): void {
    this._navigator.serviceWorker
      .register(`/assets/firebase-messaging-sw${environment.production ? '.prod' : ''}.js`)
      .then(() => {
        this.requestNotificationPermission();
      });
  }

  hideAllConsoleLogs(): void {
    if (!window.console || !environment.production) {
      return;
    }
    const methods = ['log', 'debug', 'warn', 'info'];
    for (const method of methods) {
      // eslint-disable-next-line no-console,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      console[method] = (): void => {
      };
    }
  }
}
