import { Component, Input } from '@angular/core';
import { BookingModel } from '@tante-tobi-web/infrastructure';
import { BookingService } from '../../../../../services/booking';

@Component({
  selector: 'instructor-download-invoices',
  templateUrl: './download-invoices.component.html',
})
export class DownloadInvoicesComponent {
  @Input('row') statementDetails!: BookingModel.IStatementInvoice;
  @Input('cell') id!: BookingModel.Booking['id'];

  constructor(private _bookingService: BookingService) {}

  openInvoice() {
    this._bookingService.downloadStatement(this.statementDetails.id).subscribe({ next: ({ mediaUrl }) => window.open(mediaUrl, '_blank') });
  }
}
