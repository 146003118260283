import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Form, SelectField, SelectOption } from '@ezzabuzaid/ngx-form-factory';
import { TranslocoService } from '@ngneat/transloco';
import { StatementsService } from '@tante-tobi-web/infrastructure';
import { map, mergeAll, tap, toArray } from 'rxjs';
@Component({
  selector: 'instructor-statements-filter',
  styles: [
    `
      :host {
        ::ng-deep {
          .fields-wrapper {
            width: 15%;
          }
          ngx-field-factory {
            min-width: 20% !important;
          }
          .mat-form-field.mat-form-field-appearance-fill
            .mat-form-field-wrapper
            .mat-form-field-flex {
            border-radius: 22px;
          }

          .mat-form-field-label-wrapper .mat-form-field-label {
            color: #929292 !important;
          }
        }
      }
    `,
  ],
  templateUrl: './statements-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatementsFilterComponent implements OnInit {
  @Output() filter = new EventEmitter();
  private _categories$ = this.statementsService.getCategories().pipe(
    mergeAll(),
    map((item) => new SelectOption(item.id, item.name)),
    toArray(),
    tap((list) =>
      list.unshift(
        new SelectOption(
          '',
          this.translocoService.translate('FINANCE.SELECT')
        )
      )
    )
  );

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  filterForm = new Form({
    categoryId: new SelectField({
      value: '',
      options: this._categories$,
      section: 'area_1',
      label: this.translocoService.translate('FINANCE.CATEGORIES'),
      class: 'text-gray-400',
    }),
  });
  constructor(
    private statementsService: StatementsService,
    private translocoService: TranslocoService
  ) {}
  ngOnInit(): void {}
  getFilters(): Record<string, any> {
    return this.filterForm.value;
  }

  get isInInitialState() {
    return Object.values(this.getFilters()).every((it) => it === '');
  }

  get isFilterEmpty() {
    return (
      this.isInInitialState && !this.filterForm.dirty && !this.dateRange.dirty
    );
  }

  reset() {
    this.filterForm.reset({
      categoryId: '',
    });
    this.dateRange.reset({
      start: '',
      end: '',
    });
    this.filter.emit(this.getFilterQuery());
    this.filterForm.markAsPristine();
    this.dateRange.markAsPristine();
  }
  applyFilter() {
    console.log(this.start, this.end);
    this.filter.emit(this.getFilterQuery());
    if (this.isInInitialState) {
      this.filterForm.markAsPristine();
      this.dateRange.markAsPristine();
    }
  }

  formatDate(date: Date) {
    return `${date.getFullYear()}-${this.coerceMonthNumber(
      date.getMonth()
    )}-${this.coerceDayNumber(date.getDate())}`;
  }

  coerceMonthNumber(month: number) {
    month += 1;
    return month < 10 ? `0${month}` : `${month}`;
  }

  coerceDayNumber(day: number) {
    return day < 10 ? `0${day}` : `${day}`;
  }

  get start() {
    if (!this.dateRange.get('start')?.value) {
      return undefined;
    }
    return this.formatDate(this.dateRange.get('start')?.value);
  }

  get end() {
    if (!this.dateRange.get('end')?.value) {
      return undefined;
    }
    return this.formatDate(this.dateRange.get('end')?.value);
  }

  getFilterQuery(): Record<string, any> {
    return {
      categoryId: this.filterForm.get('categoryId').value,
      startDate: this.start,
      endDate: this.end,
    };
  }
}
