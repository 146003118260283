import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TranslocoModule } from '@ngneat/transloco';
import { LumberjackModule } from '@ngworker/lumberjack';
import { LumberjackConsoleDriverModule } from '@ngworker/lumberjack/console-driver';
import { appConfig, FuseConfigModule, FuseModule } from '@tante-tobi-web/fuse';
import {
  AbstractAuthService,
  AbstractUserService,
  CoreModule,
  ENVIRONMENT,
} from '@tante-tobi-web/infrastructure';
import { LayoutModule } from '@tante-tobi-web/partials';
import { ButtonModule } from '@tante-tobi-web/widgets';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContainerComponent } from './container/container.compnent';
import { AuthService, UserService } from './services';
import { StatementsModule } from './statements/statements/statements.module';
import { TranslocoRootModule } from './transloco-root.module';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';

FullCalendarModule.registerPlugins([interactionPlugin, dayGridPlugin]);

@NgModule({
  declarations: [AppComponent, ContainerComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FuseModule,
    MatSnackBarModule,
    FuseConfigModule.forRoot(appConfig),
    HttpClientModule,
    TranslocoRootModule,
    CoreModule,
    LumberjackModule.forRoot(),
    LumberjackConsoleDriverModule.forRoot(),
    AppRoutingModule,
    LayoutModule,
    MatProgressBarModule,
    ButtonModule,
    StatementsModule,
    TranslocoModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: AbstractUserService,
      useClass: UserService,
    },
    {
      provide: AbstractAuthService,
      useClass: AuthService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
